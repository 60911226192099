<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div
          class="card"
          style="padding: 12px;"
        >
           <div id="form-fields" class="card-content">

                                     
                                            <div class="form-group row ">
                                                    <label for="staticEmail"
                                                        class="col-xs-4 col-sm-4 col-form-label">Unit</label>
                                                    <div id="1" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                  <div class="form-group row " data-name="Location">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">Location Visited</label>
                                                    <div id="2" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                               
                                                <div class="form-group row " data-name="Date">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">Date</label>
                                                    <div id="3" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
        
                                 


                                        <div class="form-group row " data-name="Yesterday Total sales">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Yesterday
                                                Total
                                                sales</label>
                                            <div id="4" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <div class="form-group row " data-name="Last Month Today">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Last
                                                Month
                                                Today</label>
                                            <div id="5" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Last Month Total Sales">
                                            <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">Last
                                                Month Total
                                                Sales</label>
                                            <div id="6" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <div class="form-group row " data-name="ADS Yesterday">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">ADS
                                                Yesterday</label>
                                            <div id="7" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                       <div class="row">
                                            <div class="form-group row col-sm-6" data-name="ADS Last Month Today">
                                                    <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">ADS
                                                        Last Month
                                                        Today</label>
                                                    <div id="8" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row col-sm-6" data-name="+/-">
                                                    <label for="staticEmail"
                                                        class="col-xs-4 col-sm-4 col-form-label">+/-</label>
                                                    <div id="9" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                       </div>


                                        <div class="form-group row " data-name="Time store open today">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Time
                                                store open
                                                today</label>
                                            <div id="10" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Time store close yesterday">
                                            <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">Time
                                                store close
                                                yesterday</label>
                                            <div id="11" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <div class="form-group row " data-name="Variance">
                                            <label for="staticEmail"
                                                class="col-xs-4 col-sm-4 col-form-label">Variance</label>
                                            <div id="12" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Missing Items Today">
                                            <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">Missing
                                                Items
                                                Today</label>
                                            <div id="13" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Missing Yesterday">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Missing
                                                Yesterday</label>
                                            <div id="14" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>


                                        <div class="form-group row " data-name="Opening Manager">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Opening
                                                Manager</label>
                                            <div id="15" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Yesterday Closing Manager">
                                            <label for="inputPassword"
                                                class="col-xs-4 col-sm-4 col-form-label">Yesterday
                                                Closing Manager</label>
                                            <div id="16" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <p class="subtitle">No. Of Crew Today:</p>
                                      <div class="row">
                                            <div class="form-group row col-sm-6" data-name="No. Of Crew Today">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">A</label>
                                                    <div id="17" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row col-sm-6" data-name="No. Of Crew Today">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">B</label>
                                                    <div id="18" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                      </div>



                                        <p class="subtitle">Yesterday Crew Total:</p>
                                       <div class="row">
                                            <div class="form-group row col-sm-6" data-name="Yesterday Crew Total">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">A</label>
                                                    <div id="19" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
        
                                                    </div>
                                                </div>
                                                <div class="form-group row col-sm-6" data-name="Yesterday Crew Total">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">B</label>
                                                    <div id="20" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
        
                                                    </div>
                                                </div>
                                       </div>

                                        <p class="subtitle">Customer Count Yesterday:</p>
                                        <div class="row">
                                                <div class="form-group row col-sm-6" data-name="Customer Count Yesterday">
                                                        <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">A</label>
                                                        <div id="21" class="col-xs-8 col-sm-8 ans">
                                                            <p class="form-control"></p>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row col-sm-6" data-name="Customer Count Yesterday">
                                                        <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">B</label>
                                                        <div id="22" class="col-xs-8 col-sm-8 ans">
                                                            <p class="form-control"></p>
                                                        </div>
                                                    </div>
            
                                        </div>


                                        <p class="subtitle">Customer Count Today:</p>
                                      <div class="row">
                                            <div class="form-group row col-sm-6" data-name="Customer Count Today">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">A</label>
                                                    <div id="23" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
        
                                                    </div>
                                                </div>
                                                <div class="form-group row col-sm-6" data-name="Customer Count Today">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">B</label>
                                                    <div id="24" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
        
                                                    </div>
                                                </div>
                                      </div>

                                        <div class="form-group row " data-name="Diesel Level Opening">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Diesel
                                                Level
                                                Opening</label>
                                            <div id="25" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Yesterday Diesel levl Closing">
                                            <label for="inputPassword"
                                                class="col-xs-4 col-sm-4 col-form-label">Yesterday Diesel
                                                levl Closing</label>
                                            <div id="26" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div class="form-group row " data-name="Special Orders">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Special
                                                Orders</label>
                                            <div id="27" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>

                                        <div class="form-group row " data-name="Generator Running">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Generator
                                                Running:</label>
                                            <div id="28" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>

                                        <div class="form-group row " data-name="Generator Running">
                                            <label for="staticEmail"
                                                class="col-xs-4 col-sm-4 col-form-label">Remark</label>
                                            <div id="29" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>

                                        <p class="subtitle">Diesel Bin Card Reading:</p>
                                       <div class="row">
                                            <div class="form-group row col-sm-6" data-name="Diesel Bin Card Reading">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label"></label>
                                                    <div id="30" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row col-sm-6" data-name="Diesel Bin Card Reading(Added)">
                                                    <label for="staticEmail"
                                                        class="col-xs-4 col-sm-4 col-form-label">Added</label>
                                                    <div id="31" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
        
                                       </div>


                                        <p>Transferred:</p>
                                       <div class="row">
                                            <div class="form-group row col-sm-6"
                                            data-name="Diesel Bin Card Reading(Transferred in)">
                                            <label for="inputPassword"
                                                class="col-xs-4 col-sm-4 col-form-label">IN</label>
                                            <div id="32" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>

                                            </div>
                                        </div>
                                        <div class="form-group row col-sm-6"
                                            data-name="Diesel Bin Card Reading(Transferred out)">
                                            <label for="inputPassword"
                                                class="col-xs-4 col-sm-4 col-form-label">OUT</label>
                                            <div id="33" class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>

                                            </div>
                                        </div>
                                       </div>

                                        <p class="subtitle">Pastries Arrival Time:</p>
                                       <div class="row">
                                            <div class="form-group col-sm-6 row " data-name="Pastries Arrival Time(1)">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">1</label>
                                                    <div id="34" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group col-sm-6 row " data-name="Pastries Arrival Time(2)">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">2</label>
                                                    <div id="35" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                       </div>

                                       <div class="row">
                                            <div class="form-group row col-sm-6" data-name="All Pastries delivered on Time?">
                                                    <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">All
                                                        Pastries
                                                        delivered on Time?</label>
                                                    <div id="36" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
        
                                                <div class="form-group row col-sm-6" data-name="Pastries Arrival">
                                                    <label for="inputPassword"
                                                        class="col-xs-4 col-sm-4 col-form-label">Remarks</label>
                                                    <div id="37" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
        
                                                    </div>
                                                </div>
                                       </div>



                                        <table class="table table-bordered">
                                            <thead>
                                                <th></th>
                                                <th>At Opening</th>
                                                <th>10:00am</th>
                                                <th>12 Noon</th>
                                                <th>3:00pm</th>
                                                <th>6:00pmn</th>
                                                <th>CLosing</th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>COLD ROOM</td>
                                                    <td id="38" class="ans">
                                                        <p></p>
                                                    </td>
                                                    <td id="39" class="ans">
                                                        <p></p>
                                                    </td>
                                                    <td id="40" class="ans">
                                                        <p></p>
                                                    </td>
                                                    <td id="41" class="ans">
                                                        <p></p>
                                                    </td>
                                                    <td id="42" class="ans">
                                                        <p></p>
                                                    </td>
                                                    <td id="43" class="ans">
                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>ROAST CHICKEN FOG</td>
                                                    <td id="44" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="45" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="46" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="47" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="48" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="49" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>SOUPS TEMP KJR</td>
                                                    <td id="50" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="51" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="52" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="53" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="54" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="55" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>LOBBY A/C</td>
                                                    <td id="56" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="57" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="58" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="59" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="60" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="61" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>CONVENIENCIES OK?</td>
                                                    <td id="62" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="63" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="64" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="65" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="66" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="67" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>KITCHEN LOBBY CLEANLINESS OK?</td>
                                                    <td id="68" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="69" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="70" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="71" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="72" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="73" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>




                                            </tbody>


                                        </table>
                                        <br>
                                        <table class="table table-bordered">
                                            <thead>
                                                <th></th>
                                                <th>At Opening</th>
                                                <th>10:00am</th>
                                                <th>12 Noon</th>
                                                <th>3:00pm</th>
                                                <th>6:00pmn</th>
                                                <th>CLosing</th>
                                                <th>Remarks</th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>FRONT PARKING LOT</td>
                                                    <td id="74" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="75" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="76" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="77" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="78" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="79" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="80" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>TV/MUSIC OK</td>
                                                    <td id="81" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="82" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="83" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="84" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="85" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="86" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="87" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>COUNTER SERVICE TIME UNDER 3MINS</td>
                                                    <td id="88" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="89" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="90" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="91" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="92" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="93" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="94" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>





                                            </tbody>
                                        </table>

                                        <br>
                                        <table class="table table-bordered red">
                                            <thead>
                                                <th></th>
                                                <th>REVC</th>
                                                <th>COLD STORE TEMP</th>
                                                <th>THAWING TEMP</th>
                                                <th>COLD ROOM TEMP</th>
                                                <th>MARINATION TEMP</th>
                                                <th>PORTION TIME</th>
                                                <th>FRYING TEMP</th>
                                                <th>COOKING TEMP</th>
                                                <th>HOLDING TEMP</th>
                                                <th>DISPLAY COUNTER TEMP</th>
                                                <th>COOLERS TEMP</th>
                                                <th>ICE CREAM SOFT SERV TEMP</th>
                                                <th>DEEP FREEZER TEMP</th>
                                            </thead>
                                            <thead>
                                                <th></th>
                                                <th>Temp+5<sup>0</sup>C</th>
                                                <th>0-5<sup>0</sup>C</th>
                                                <th>0-5<sup>0</sup>C</th>
                                                <th>-18-5<sup>0</sup>C to -23<sup>0</sup>C</th>
                                                <th>0-5<sup>0</sup>C</th>
                                                <th>45 mins</th>
                                                <th>+120<sup>0</sup>C</th>
                                                <th>+75<sup>0</sup>C</th>
                                                <th>+60<sup>0</sup>C</th>
                                                <th>+60<sup>0</sup>C</th>
                                                <th>+5<sup>0</sup>C</th>
                                                <th>-12<sup>0</sup>C</th>
                                                <th>-18<sup>0</sup>C</th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>CHICKEN</td>
                                                    <td id="95" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="96" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="97" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="98" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="99" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="100" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="101" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="102" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="103" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="104" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="105" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="106" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="107" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>BEEF, SHARKI GOAT MEAT</td>
                                                    <td id="108" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="109" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="110" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="111" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="112" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="113" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="114" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="115" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="116" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="117" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="118" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="119" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="120" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>FISH</td>
                                                    <td id="121" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="122" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="123" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="124" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="125" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="126" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="127" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="128" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="129" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="130" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="131" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="132" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="133" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>FRENCH FRIES</td>
                                                    <td id="134" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="135" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="136" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="137" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="138" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="139" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="140" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="141" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="142" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="143" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="144" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="145" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="146" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>FRESH VEGETABLES</td>
                                                    <td id="147" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="148" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="149" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="150" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="151" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="152" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="153" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="154" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="155" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="156" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="157" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="158" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="159" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>RICE/FLOUR/OIL</td>
                                                    <td id="160" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="161" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="162" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="163" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="164" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="165" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="166" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="167" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="168" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="169" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="170" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="171" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="172" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>BEVERAGES/MINERAL</td>
                                                    <td id="173" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="174" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="175" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="176" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="177" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="178" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="179" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="180" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="181" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="182" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="183" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="184" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="185" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>ICE CREAM</td>
                                                    <td id="186" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="187" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="188" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="189" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="190" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="191" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="192" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="193" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="194" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="195" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="196" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="197" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="198" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>BREAD/PASTRY</td>
                                                    <td id="199" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="200" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="201" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="202" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="203" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="204" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="205" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="206" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="207" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="208" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="209" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="210" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="211" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td>SALADS/SANDWICH</td>
                                                    <td id="212" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="213" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="214" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="215" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="216" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="217" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="218" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="219" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="220" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="221" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="222" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="223" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="224" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>BURGER PATTY</td>
                                                    <td id="225" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="226" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="227" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="228" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="229" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="230" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="231" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="232" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="233" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="234" class="ans">

                                                        <p></p>
                                                    </td>
                                                    <td id="235" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="236" class="ans grey">

                                                        <p></p>
                                                    </td>
                                                    <td id="237" class="ans">

                                                        <p></p>
                                                    </td>
                                                </tr>



                                            </tbody>

                                        </table>
                                        <br>
                                        <br>
                                        <div id="238" class="form-group row " data-name="Closing Time">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Closing
                                                Time</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div id="239" class="form-group row " data-name="Closing Stock">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Closing
                                                Stock</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <div id="240" class="form-group row " data-name="Closing Stock">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Security
                                                Verify</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>

                                        <div id="241" class="form-group row " data-name="Gen. Off Time">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Gen.
                                                Off
                                                Time</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                        <div id="242" class="form-group row " data-name="Remarks">
                                            <label for="staticEmail"
                                                class="col-xs-4 col-sm-4 col-form-label">Remarks</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>

                                        <div id="243" class="form-group row " data-name="Audit">
                                            <label for="staticEmail"
                                                class="col-xs-4 col-sm-4 col-form-label">Audit</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>
                                        <div id="244" class="form-group row " data-name="Closing Manager">
                                            <label for="staticEmail" class="col-xs-4 col-sm-4 col-form-label">Closing
                                                Manager</label>
                                            <div class="col-xs-8 col-sm-8 ans">
                                                <p class="form-control"></p>
                                            </div>
                                        </div>



                                    </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () {
    this.getForm(this.$route.params.id);
  },
  methods: {
    getForm (id) {
      var recent = {
        what: "redbookview",
        // showLoader: "no",
        params: {
          form_id: id
        }
      };

      this.$socket
        .makeGetRequest(recent)
        .then(response => {
          if (response.type == "redbookview") {
            for (let i = 0; i <= 244; i++) {
              response.data.forEach(item => {
               if (i == item.questionno) {
                    // console.log(item.questiontext)
                     if (item.questiontext == "Location Visited Location Visited") {
                    let stores = this.$store.getters.stores
                    stores.forEach(j => {
                      if (j.id == item.answers) {
                        item.answers = j.address + ", " + j.location;
                      }
                    })
                  }
                  document
                    .getElementById(i)
                    .querySelectorAll("p")[0].innerHTML = item.answers;
                }
              });
            }

            // console.log(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
<style>

table.red thead th{
    font-size: 9px;
    padding: 1px;
}
table.red tbody tr {
    font-size: 10px;
}
td.grey{
  background: #e9ecef !important;
  border: none;
}
</style>
