<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div
          class="card"
          style="padding: 20px;"
        >
             <div id="form-fields" class="card-content">
                                         <div class="form-group row">
                                                    <label for="staticEmail" class=" col-xs-4 col-sm-4 col-form-label">VISITOR'S NAME</label>
                                                    <div id="1" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                            <div class="form-group row">
                                                    <label for="staticEmail" class=" col-xs-4 col-sm-4 col-form-label">UNIT</label>
                                                    <div id="2" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="inputPassword" class=" col-xs-4 col-sm-4 col-form-label">DATE</label>
                                                    <div id="3" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="inputPassword" class=" col-xs-4 col-sm-4 col-form-label">LOCATION VISITED</label>
                                                    <div id="4" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <th></th>
                                                        <th>Morning</th>
                                                        <th >Peak Period</th>
                                                        <th >CLosing(30mins)</th>
                                                        <th></th>
                                                    </thead>
                                                    <thead>
                                                            <th>HOST LOBBY</th>
                                                            <th>Yes/No</th>
                                                            <th>Yes/No</th>
                                                            <th>Yes/No</th>
                                                            <th>Comments/Remarks</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Is the shop floor swept/buffed?</td>
                                                            <td id="5" class="ans">
                                                                    <p ></p>
                                                                </td>
                                                            <td id="6" class="ans">
                                                                    <p></p>
                                                                </td>
                                                            <td id="7" class="ans"><p ></p>  </td>
                                                            <td id="8" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td>Is the window/door cleaned?</td>
                                                             <td id="9" class="ans"><p ></p>  </td>
                                                            <td id="10" class="ans"><p ></p>  </td>
                                                            <td id="11" class="ans"><p ></p>  </td>
                                                            <td id="12" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the chair/table cleaned?</td>
                                                            <td id="13" class="ans"><p ></p>  </td>
                                                            <td id="14" class="ans"><p ></p>  </td>
                                                            <td id="15" class="ans"><p ></p>  </td>
                                                            <td id="16" class="ans"><p ></p>  </td>
                                                    
                                                        </tr>
                                                        <tr>
                                                            <td>Is the toilet cleaned hourly according to the checklist?</td>
                                                                    <td id="17" class="ans"><p ></p>  </td>
                                                            <td id="18" class="ans"><p ></p>  </td>
                                                            <td id="19" class="ans"><p ></p>  </td>
                                                            <td id="20" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                
                                                            <td>Is there toilet rolls/hand wash in place?</td>
                                                             <td id="21" class="ans"><p ></p>  </td>
                                                            <td id="22" class="ans"><p ></p>  </td>
                                                            <td id="23" class="ans"><p ></p>  </td>
                                                            <td id="24" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the Air conditioners on/off for the day?</td>
                                                            <td id="25" class="ans"><p ></p>  </td>
                                                            <td id="26" class="ans"><p ></p>  </td>
                                                            <td id="27" class="ans"><p ></p>  </td>
                                                            <td id="28" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is all Spot lights & insect catcher light on/off?</td>
                                                            <td id="29" class="ans"><p ></p>  </td>
                                                            <td id="30" class="ans"><p ></p>  </td>
                                                            <td id="31" class="ans"><p ></p>  </td>
                                                            <td id="32" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the TV & sound system on/off and set appropraitely?</td>
                                                            <td id="33" class="ans"><p ></p>  </td>
                                                            <td id="34" class="ans"><p ></p>  </td>
                                                            <td id="35" class="ans"><p ></p>  </td>
                                                            <td id="36" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the lobby free of cobwebs/flies or any crawling insects?</td>
                                                            <td id="37" class="ans"><p ></p>  </td>
                                                            <td id="38" class="ans"><p ></p>  </td>
                                                            <td id="39" class="ans"><p ></p>  </td>
                                                            <td id="40" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                
                                                    </tbody>
                                
                                                    <thead>
                                                        <th>CASH POINT FOH</th>
                                                        <th colspan="4"></th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td  >Is the Display fridge cleaned/filled timely enough?</td>
                                                             <td id="41" class="ans"><p ></p>  </td>
                                                            <td id="42" class="ans"><p ></p>  </td>
                                                            <td id="43" class="ans"><p ></p>  </td>
                                                            <td id="44" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the Counter tops/till machines cleaned and well arranged?</td>
                                                            <td id="45" class="ans"><p ></p>  </td>
                                                            <td id="46" class="ans"><p ></p>  </td>
                                                            <td id="47" class="ans"><p ></p>  </td>
                                                            <td id="48" class="ans"><p ></p>  </td>
                                                         
                                                        </tr>
                                                        <tr>
                                                            <td>Is the Menu boards cleaned daily?</td>
                                                               <td id="49" class="ans"><p ></p>  </td>
                                                            <td id="50" class="ans"><p ></p>  </td>
                                                            <td id="51" class="ans"><p ></p>  </td>
                                                            <td id="52" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are the Wall tiles in the severy area cleaned and free of stains?</td>
                                                            <td id="53" class="ans"><p ></p>  </td>
                                                            <td id="54" class="ans"><p ></p>  </td>
                                                            <td id="55" class="ans"><p ></p>  </td>
                                                            <td id="56" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                
                                                            <td>Are the Forks & Knives with tooth picks well wrapped in advance?</td>
                                                            <td id="57" class="ans"><p ></p>  </td>
                                                            <td id="58" class="ans"><p ></p>  </td>
                                                            <td id="59" class="ans"><p ></p>  </td>
                                                            <td id="60" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are Carrier Bags/disposable cups/tumblers available for the day?</td>
                                                            <td id="61" class="ans"><p ></p>  </td>
                                                            <td id="62" class="ans"><p ></p>  </td>
                                                            <td id="63" class="ans"><p ></p>  </td>
                                                            <td id="64" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are serviette available and well arranged?</td>
                                                            <td id="65" class="ans"><p ></p>  </td>
                                                            <td id="66" class="ans"><p ></p>  </td>
                                                            <td id="67" class="ans"><p ></p>  </td>
                                                            <td id="68" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is there Availability of all products and 25% minimum replenishment per SOP?</td>
                                                            <td id="69" class="ans"><p ></p>  </td>
                                                            <td id="70" class="ans"><p ></p>  </td>
                                                            <td id="71" class="ans"><p ></p>  </td>
                                                            <td id="72" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are display cabinets cleaned on/off and regulated at the right temperature?</td>
                                                            <td id="73" class="ans"><p ></p>  </td>
                                                            <td id="74" class="ans"><p ></p>  </td>
                                                            <td id="75" class="ans"><p ></p>  </td>
                                                            <td id="76" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is coffee machine turned on and all materials and dispenser water available?</td>
                                                            <td id="77" class="ans"><p ></p>  </td>
                                                            <td id="78" class="ans"><p ></p>  </td>
                                                            <td id="79" class="ans"><p ></p>  </td>
                                                            <td id="80" class="ans"><p ></p>  </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Is the chips dump well cleaned and heated ambient light turned on?</td>
                                                              <td id="81" class="ans"><p ></p>  </td>
                                                            <td id="82" class="ans"><p ></p>  </td>
                                                            <td id="83" class="ans"><p ></p>  </td>
                                                            <td id="84" class="ans"><p ></p>  </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Has cash skimms been done from each register(Minimum twice per shift)</td>
                                                              <td id="85" class="ans"><p ></p>  </td>
                                                            <td id="86" class="ans"><p ></p>  </td>
                                                            <td id="87" class="ans"><p ></p>  </td>
                                                            <td id="88" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td>Is all packaging well stacked and arranged?</td>
                                                             <td id="89" class="ans"><p ></p>  </td>
                                                            <td id="90" class="ans"><p ></p>  </td>
                                                            <td id="91" class="ans"><p ></p>  </td>
                                                            <td id="92" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the Light in all the chillers and equipments on/off and well lite?</td>
                                                            <td id="93" class="ans"><p ></p>  </td>
                                                            <td id="94" class="ans"><p ></p>  </td>
                                                            <td id="95" class="ans"><p ></p>  </td>
                                                            <td id="96" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                    </tbody>
                                
                                                    <thead>
                                                        <th>KITCHEN BOH </th>
                                                        <th colspan="4"></th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td  >Is the Fryer & gas burners well cleaned and on/off?</td>
                                                             <td id="97" class="ans"><p ></p>  </td>
                                                            <td id="98" class="ans"><p ></p>  </td>
                                                            <td id="99" class="ans"><p ></p>  </td>
                                                            <td id="100" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is the Rotisserie Rational Machine cleaned and on/off for the day?</td>
                                                            <td id="101" class="ans"><p ></p>  </td>
                                                            <td id="102" class="ans"><p ></p>  </td>
                                                            <td id="103" class="ans"><p ></p>  </td>
                                                            <td id="104" class="ans"><p ></p>  </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Has the oil check for the deep fryer been done for quality?</td>
                                                              <td id="105" class="ans"><p ></p>  </td>
                                                            <td id="106" class="ans"><p ></p>  </td>
                                                            <td id="107" class="ans"><p ></p>  </td>
                                                            <td id="108" class="ans"><p ></p>  </td>
                                                         
                                                        </tr>
                                                        <tr>
                                                            <td>Is the extractor on/off and clear of all oil residue/build up?</td>
                                                               <td id="109" class="ans"><p ></p>  </td>
                                                            <td id="110" class="ans"><p ></p>  </td>
                                                            <td id="111" class="ans"><p ></p>  </td>
                                                            <td id="112" class="ans"><p ></p>  </td>

                                                        </tr>
                                                        <tr>
                                
                                                            <td>Is the vegetable dicer cleaned & well stacked?</td>
                                                            <td id="113" class="ans"><p ></p>  </td>
                                                            <td id="114" class="ans"><p ></p>  </td>
                                                            <td id="115" class="ans"><p ></p>  </td>
                                                            <td id="116" class="ans"><p ></p>  </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Was all food prepared sampled before it is passed to check for quality and taste?</td>
                                                              <td id="117" class="ans"><p ></p>  </td>
                                                            <td id="118" class="ans"><p ></p>  </td>
                                                            <td id="119" class="ans"><p ></p>  </td>
                                                            <td id="120" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are the Cold room/freexer cleaned based on the cleaning schedule?</td>
                                                            <td id="121" class="ans"><p ></p>  </td>
                                                            <td id="122" class="ans"><p ></p>  </td>
                                                            <td id="123" class="ans"><p ></p>  </td>
                                                            <td id="124" class="ans"><p ></p>  </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Are all the Lights in the different BOH sections working? and on/off?</td>
                                                              <td id="125" class="ans"><p ></p>  </td>
                                                            <td id="126" class="ans"><p ></p>  </td>
                                                            <td id="127" class="ans"><p ></p>  </td>
                                                            <td id="128" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>HAs all refuse been disposed timely enough(Hall full! Half emptied)</td>
                                                            <td id="129" class="ans"><p ></p>  </td>
                                                            <td id="130" class="ans"><p ></p>  </td>
                                                            <td id="131" class="ans"><p ></p>  </td>
                                                            <td id="132" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td>Is the kitchen floors/Corridors swept and mopped?</td>
                                                             <td id="133" class="ans"><p ></p>  </td>
                                                            <td id="134" class="ans"><p ></p>  </td>
                                                            <td id="135" class="ans"><p ></p>  </td>
                                                            <td id="136" class="ans"><p ></p>  </td>

                                                        </tr>
                                                        <tr>
                                                            <td>Is staff changing/cloak room cleaned & lockers properly arranged?</td>
                                                            <td id="137" class="ans"><p ></p>  </td>
                                                            <td id="138" class="ans"><p ></p>  </td>
                                                            <td id="139" class="ans"><p ></p>  </td>
                                                            <td id="140" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                
                                                    </tbody>
                                
                                                    <thead>
                                                        <th>ADDITIONAL CHECKS </th>
                                                        <th colspan="4"></th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td  >Is the REDBOOK filled timely? accurately? and correctly?</td>
                                                             <td id="141" class="ans"><p ></p>  </td>
                                                            <td id="142" class="ans"><p ></p>  </td>
                                                            <td id="143" class="ans"><p ></p>  </td>
                                                            <td id="144" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Is CAYGO the order of the day?</td>
                                                            <td id="145" class="ans"><p ></p>  </td>
                                                            <td id="146" class="ans"><p ></p>  </td>
                                                            <td id="147" class="ans"><p ></p>  </td>
                                                            <td id="148" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Are all staff dressed correctly as per SOP?</td>
                                                            <td id="149" class="ans"><p ></p>  </td>
                                                            <td id="150" class="ans"><p ></p>  </td>
                                                            <td id="151" class="ans"><p ></p>  </td>
                                                            <td id="152" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td>Are the hand wash soap units filled and are staff washing their hands frequently?</td>
                                                             <td id="153" class="ans"><p ></p>  </td>
                                                            <td id="154" class="ans"><p ></p>  </td>
                                                            <td id="155" class="ans"><p ></p>  </td>
                                                            <td id="156" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                
                                                            <td>Was the grease fat trap cleaned(Must clean at least once daily</td>
                                                            <td id="157" class="ans"><p ></p>  </td>
                                                            <td id="158" class="ans"><p ></p>  </td>
                                                            <td id="159" class="ans"><p ></p>  </td>
                                                            <td id="160" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td>Is the weekly staff schedules/roaster prepared in advanced and correct?</td>
                                                             <td id="161" class="ans"><p ></p>  </td>
                                                            <td id="162" class="ans"><p ></p>  </td>
                                                            <td id="163" class="ans"><p ></p>  </td>
                                                            <td id="164" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Was there any material unavailability or shortage affecting the shift or day sales?</td>
                                                            <td id="165" class="ans"><p ></p>  </td>
                                                            <td id="166" class="ans"><p ></p>  </td>
                                                            <td id="167" class="ans"><p ></p>  </td>
                                                            <td id="168" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Was the morning/Afternoon stock count carried out?</td>
                                                            <td id="169" class="ans"><p ></p>  </td>
                                                            <td id="170" class="ans"><p ></p>  </td>
                                                            <td id="171" class="ans"><p ></p>  </td>
                                                            <td id="172" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Has the previous day's and shift control been carried out?</td>
                                                            <td id="173" class="ans"><p ></p>  </td>
                                                            <td id="174" class="ans"><p ></p>  </td>
                                                            <td id="175" class="ans"><p ></p>  </td>
                                                            <td id="176" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Has the food cost report been done for the previous day?</td>
                                                            <td id="177" class="ans"><p ></p>  </td>
                                                            <td id="178" class="ans"><p ></p>  </td>
                                                            <td id="179" class="ans"><p ></p>  </td>
                                                            <td id="180" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>All materials received via suppliers/transfers in the best consition and quality?</td>
                                                            <td id="181" class="ans"><p ></p>  </td>
                                                            <td id="182" class="ans"><p ></p>  </td>
                                                            <td id="183" class="ans"><p ></p>  </td>
                                                            <td id="184" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>Has morning/Afternoon cash sales been counted/verified and locked in the safe?</td>
                                                            <td id="185" class="ans"><p ></p>  </td>
                                                            <td id="186" class="ans"><p ></p>  </td>
                                                            <td id="187" class="ans"><p ></p>  </td>
                                                            <td id="188" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>All equipment at the close of business inspected cleaned and turned off?</td>
                                                            <td id="189" class="ans"><p ></p>  </td>
                                                            <td id="190" class="ans"><p ></p>  </td>
                                                            <td id="191" class="ans"><p ></p>  </td>
                                                            <td id="192" class="ans"><p ></p>  </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td>How was the customer experience today in both shifts?</td>
                                                            <td id="193" class="ans"><p ></p>  </td>
                                                            <td id="194" class="ans"><p ></p>  </td>
                                                            <td id="195" class="ans"><p ></p>  </td>
                                                            <td id="196" class="ans"><p ></p>  </td>
                                                           
                                                        </tr>
                                
                                                    </tbody>
                                
                                                </table>
                                                <br>
                                                <div class="form-group row">
                                                    <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">Shift Manager(Morning) Name & Sign</label>
                                                    <div id="197" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="inputPassword" class="col-xs-4 col-sm-4 col-form-label">Shift Manager(Afternoon) Name & Sign</label>
                                                    <div id="198" class="col-xs-8 col-sm-8 ans">
                                                        <p class="form-control"></p>
                                                    </div>
                                                </div>
                                    </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () {
    this.getForm(this.$route.params.id);
  },
  methods: {
    getForm (id) {
      var recent = {
        what: "greenbookview",
        // showLoader: "no",
        params: {
          form_id: id
        }
      };

      this.$socket
        .makeGetRequest(recent)
        .then(response => {
          if (response.type == "greenbookview") {
            for (let i = 0; i <= 342; i++) {
              response.data.forEach(item => {
                if (i == item.questionno) {
                    // console.log(item.questiontext)
                     if (item.questiontext == "Location Visited Location Visited") {
                    let stores = this.$store.getters.stores
                    stores.forEach(j => {
                      if (j.id == item.answers) {
                        item.answers = j.address + ", " + j.location;
                      }
                    })
                  }
                  document
                    .getElementById(i)
                    .querySelectorAll("p")[0].innerHTML = item.answers;
                }
              });
            }

            // console.log(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
<style scoped>
.form-box {
  border: 1px solid #dee2e6;
  height: 120px;
  padding: 5px;
}
</style>
