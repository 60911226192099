<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div
          class="card"
          style="padding: 20px;"
        >
          <div
            id="form-fields"
            class="card-content"
          >
            <div
              class="form-group row ans"
              data-name="Auditor's Name"
            >
              <label
                for="staticEmail"
                class="col-xs-4 col-sm-4 col-form-label"
              >Auditor's Name</label>
              <div
                id="1"
                class="col-xs-8 col-sm-8 ans"
              >
                <p class="form-control"></p>
              </div>
            </div>
            <div
              class="form-group row ans"
              data-name="Shop Name"
            >
              <label
                for="staticEmail"
                class="col-xs-4 col-sm-4 col-form-label"
              >Shop</label>
              <div
                id="2"
                class="col-xs-8 col-sm-8 ans"
              >
                <p class="form-control"></p>
              </div>
            </div>
            <div
              class="form-group row ans"
              data-name="Date"
            >
              <label
                for="inputPassword"
                class="col-xs-4 col-sm-4 col-form-label"
              >Date</label>
              <div
                id="3"
                class="col-xs-8 col-sm-8 ans"
              >
                <p class="form-control"></p>
              </div>
            </div>

            <div>
              <h5 class="subtitle">Direction</h5>
              <p>This checklist is used to determine areas in Operations requiring
                corrective action. Record
                corrective action taken and keep completed records in a file for future
                reference.</p>
            </div>
            <div>
              <h5 class="subtitle"><u>A. HYGIENE AND SANITATION</u></h5>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1 text-bold">1.</p>
                <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> PERSONAL HYGIENE </p>
                <p class="col-md-2 col-sm-2 col-xs-2 text-bold">Score(1-5)</p>
                <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Employees wear clean and proper
                  uniform
                  including
                  shoes.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="4"
                    class="hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="5"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >

                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                <p class="col-md-5 col-sm-5 col-xs-5"> Effective hair restraints are
                  properly worn</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="6"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="7"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Fingernails are short, unpolished,
                  and clean (no artificial nails).</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="8"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="9"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">No jewelry like ring, hand band,
                  watch
                  or bracelets worn by staff.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="10"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="11"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Hands are washed properly,
                  frequently, and at appropriate times.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="12"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="13"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Burns, wounds, sores or scabs, or
                  splints and water-proof bandages
                  on hands are bandaged and completely covered with a foodservice
                  glove while handling food.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="14"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="15"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Eating, drinking, chewing gum,
                  smoking, or using tobacco not
                  allowed only in designated areas away from preparation, service,
                  storage, and ware washing areas.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="16"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="17"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">h.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Employees use disposable tissues
                  when coughing or sneezing and
                  then immediately wash hands.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="18"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="19"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                  data-name="Employees use disposable tissues
                                                when coughing or sneezing and
                                                then immediately wash hands."
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">i.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Employees appear in good health.
                </p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="20"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="21"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">j.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Hand sinks are unobstructed,
                  operational and clean.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="22"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="23"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">k.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Hand sinks are stocked with soap,
                  disposable towels, and warm water</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="24"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="25"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">l.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">A handwashing reminder sign is
                  posted.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="26"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="27"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">m.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Are BOH crew in approved PPE
                  aprons and caps?</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="28"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="29"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1 text-bold">2.</p>
                <p class="col-md-5 col-sm-5 col-xs-5 text-bold">HOT HOLDING </p>
                <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Hot holding unit and display is
                  clean.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="30"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="31"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                <p class="col-md-5 col-sm-5 col-xs-5"> Food is heated to the required
                  safe internal
                  temperature before
                  placing in hot holding. Hot holding units are not used to reheat
                  potentially hazardous foods like chicken and fish.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="32"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="33"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Hot holding unit is pre-heated
                  before hot food is
                  placed in unit</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="34"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="35"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Temperature of hot food being held
                  is at or above 60ºC.
                </p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="36"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="37"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                <p class="col-md-5 col-sm-5 col-xs-5"> Food is protected from
                  contamination by proper
                  separation in warmer. </p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="38"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="39"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1 text-bold">3.</p>
                <p class="col-md-5 col-sm-5 col-xs-5 text-bold">COLD HOLDING </p>
                <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Refrigerators, cold rooms are kept
                  clean and organized.
                </p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="40"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="41"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Temperature of cold food being
                  held is at or below 5ºC.
                </p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="42"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="43"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                <p class="col-md-5 col-sm-5 col-xs-5"> Food is protected from
                  contamination by proper
                  separation and
                  coverage.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="44"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="45"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Is the RED book properly filled
                  and all values entered
                  real time?</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="46"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="47"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>

            </div>
            <hr>
            <div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1 text-bold">4.</p>
                <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> REFRIGERATOR, FREEZER,
                  AND COLD
                  ROOM </p>
                <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                <p class="col-md-5 col-sm-5 col-xs-5">Are Manager using the thermometers
                  and is it available
                  and accurate.</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="48"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="49"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
              </div>
              <div class="row container">
                <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                <p class="col-md-5 col-sm-5 col-xs-5"> Are temperatures appropriate for
                  ALL products in cold
                  storage?</p>
                <div class="col-md-2 col-sm-2 col-xs-2">
                  <div
                    id="50"
                    class=" hygiene col-form-check form-check-inline ans"
                  >
                    <p class="form-control"></p>

                  </div>

                </div>
                <div
                  id="51"
                  class="col-md-3 col-sm-3 col-xs-3 ans"
                >
                  <p class="form-control"></p>
                </div>
                <div class="
                  row
                  container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are Raw materials stored on
                    pallets off the floor in
                    the cold room?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="52"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="53"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are refrigerator, freezer and cold
                    room unit clean and
                    neat?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="54"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="55"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are proper chilling procedures
                    used?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="56"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="57"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all food properly wrapped,
                    labeled and dated?.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="58"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="59"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the FIFO (First In, First Out)
                    method of inventory
                    management is
                    Used is the cold and dry stores?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="60"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="61"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">h.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all product temperatures in
                    all refrigerators,
                    freezers and cold room
                    monitored and documented in the red book at recommended
                    period during.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="62"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="63"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">i.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are canned food stored in original
                    container or in food
                    grade plastic .</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="64"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="65"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">5.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> CLEANING AND SANITIZING
                  </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the 3-compartment sink properly
                    set up for ware
                    washing
                    with sanitizers.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="66"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="67"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is Kitchen water clean and free of
                    grease, particles
                    and other particles? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="68"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="69"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is water temperature correct for
                    wash and rinse?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="70"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="71"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are chemical sanitizer used in the
                    wash sinks, cash
                    points and
                    fryer areas?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="72"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="73"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are small ware and utensils
                    allowed to air dry or
                    properly wiped
                    with clean napkin?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="74"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="75"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are wiping cloths are stored in
                    sanitizing solution
                    while in use.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="76"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="77"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">6.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> EXTERIOR AREAS </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the external surrounding and
                    the car park well
                    illuminated?
                    .</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="78"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="79"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are steps, grounds and parking lot
                    floors in good
                    repair, free of holes
                    or obstruction and well illuminated.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="80"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="81"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is generator area kept clean and
                    free of scraps and
                    clutter? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="82"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="83"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all the drains cleaned up,
                    well maintained and free
                    of debris? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="84"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="85"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">7.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> LOCKER ROOM AND BATH
                    ROOMS </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are the lockers ok, well
                    maintained and in good order?
                    .</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="86"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="87"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are the locker rooms kept clean
                    and free of foul odour?
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="88"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="89"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are the bath rooms and toilets
                    clean and well refreshed
                    including
                    customer toilets? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="90"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="91"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are the showers and taps flowing
                    and functional?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="92"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="93"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">8.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold">UTENSILS AND EQUIPMENT
                  </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all small equipment and
                    utensils, including cutting
                    boards and
                    knives, cleaned and sanitized between use and air dried?.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="94"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="95"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are all pots and burners cleaned
                    and glowing with blue
                    flame?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="96"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="97"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are deep fryers, BOH fridge and
                    ovens clean? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="98"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="99"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are work surfaces cleaned and
                    sanitized between use</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="100"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="101"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are thermometers calibrated,
                    cleaned and sanitized
                    after each use </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="102"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="103"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Is can opener clean?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="104"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="105"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are drawers and racks clean? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="106"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="107"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">9.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold">LARGE EQUIPMENT </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is toaster and blender clean?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="108"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="109"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Is grinding machine opened,
                    cleaned, and sanitized 3ce
                    weekly </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="110"
                      class=" hygiene col-form-check form-check-inline ans"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="111"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is grinding machine opened,
                                                cleaned, and sanitized 3ce
                                                weekly"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are boxes, containers, and
                    recyclables removed from
                    site? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="112"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Are boxes, containers, and
                                                    recyclables removed from
                                                    site? "
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="113"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are boxes, containers, and
                                                recyclables removed from
                                                site? "
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the hot water heater working
                    efficiently and in good
                    order?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="114"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Is the hot water heater working
                                                    efficiently and in good
                                                    order?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="115"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is the hot water heater working
                                                efficiently and in good
                                                order?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are exhaust hood, grills and
                    filters of extractor
                    clean? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="116"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Are exhaust hood, grills and
                                                    filters of extractor
                                                    clean?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="117"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are exhaust hood, grills and
                                                filters of extractor
                                                clean?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are all visible plugs and sockets
                    functional and in
                    good order? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="118"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Are all visible plugs and sockets
                                                    functional and in
                                                    good order?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="119"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all visible plugs and sockets
                                                functional and in
                                                good order?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">10.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold">GARBAGE STORAGE AND
                    DISPOSAL
                  </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the kitchen garbage bucket
                    clean, lined and kept
                    covered? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="120"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Is the kitchen garbage bucket
                                                    clean, lined and kept
                                                    covered?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="121"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is the kitchen garbage bucket
                                                clean, lined and kept
                                                covered?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Is the garbage bin and dump area
                    clean and odour-free.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="122"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Is the garbage bin and dump area
                                                    clean and odour-free."
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="123"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is the garbage bin and dump area
                                                clean and odour-free."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are garbage bucket emptied as
                    necessary? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="124"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Are garbage bucket emptied as
                                                    necessary?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="125"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are garbage bucket emptied as
                                                necessary?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">11.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold">PEST CONTROL </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is there any evidence of rodent
                    and insect pest in the
                    shop? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="126"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Is there any evidence of rodent
                                                    and insect pest in the
                                                    shop?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="127"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is there any evidence of rodent
                                                and insect pest in the
                                                shop?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Is there a regular schedule of
                    pest control by a
                    licensed pest control?
                    operator with evidence of fumigation certificates in the shop?
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="128"
                      class=" hygiene col-form-check form-check-inline ans"
                      data-name="Is there a regular schedule of pest control by a
                                                    licensed pest control?
                                                    operator with evidence of fumigation certificates in the shop?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="129"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is there a regular schedule of
                                                pest control by a
                                                licensed pest control?
                                                operator with evidence of fumigation certificates in the shop?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <div
                    id="130"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="131"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <!-- house keeping -->

              <div>
                <h4 class="subtitle"><u>B. HOUSE KEEPING</u></h4>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">12.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> DRY FOOD STORAGE </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all stocks properly and
                    securely stacked; stored
                    racks and on
                    pallets 6 to 8 inches off the floor?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="132"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are all stocks properly and
                                                    securely stacked; stored
                                                    racks and on
                                                    pallets 6 to 8 inches off the floor?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="133"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all stocks properly and
                                                securely stacked; stored
                                                racks and on
                                                pallets 6 to 8 inches off the floor?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Good housekeeping maintained,
                    aisles clear, storage
                    room orderly,
                    Floors free of debris</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="134"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Good housekeeping maintained,
                                                    aisles clear, storage
                                                    room orderly,
                                                    Floors free of debris"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="135"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Good housekeeping maintained,
                                                aisles clear, storage
                                                room orderly,
                                                Floors free of debris"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Shelving racks in good repair and
                    secured to avoid
                    tipping</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="136"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Shelving racks in good repair and
                                                    secured to avoid
                                                    tipping"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="137"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Shelving racks in good repair and
                                                secured to avoid
                                                tipping"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all food labeled with name and
                    received date.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="138"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are all food labeled with name and
                                                    received date."
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="139"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all food labeled with name and
                                                received date."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are food materials stored in
                    containers with tight
                    fitting lids and
                    labeled with common name.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="140"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are food materials stored in
                                                    containers with tight
                                                    fitting lids and
                                                    labeled with common name"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="141"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are food materials stored in
                                                containers with tight
                                                fitting lids and
                                                labeled with common name"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is FIFO (First In, First Out)
                    method of inventory
                    management is
                    used.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="142"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Is FIFO (First In, First Out)
                                                    method of inventory
                                                    management is
                                                    used."
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="143"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is FIFO (First In, First Out)
                                                method of inventory
                                                management is
                                                used."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are there any bulging or leaking
                    canned foods? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="144"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are there any bulging or leaking
                                                    canned foods?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="145"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are there any bulging or leaking
                                                canned foods?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">h.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are foods protected from
                    contamination?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="146"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are foods protected from
                                                    contamination?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="147"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are foods protected from
                                                contamination?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">i.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all food storage surfaces
                    clean.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="148"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are all food storage surfaces
                                                    clean."
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="149"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all food storage surfaces
                                                clean."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">j.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all chemicals clearly labeled
                    and stored away from
                    food and food-
                    related products?.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="150"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Are all chemicals clearly labeled
                                                    and stored away from
                                                    food and food-
                                                    related products?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="151"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all chemicals clearly labeled
                                                and stored away from
                                                food and food-
                                                related products?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>
              <div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">13.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold">FLOORS AND WALKING
                    SURFACES </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is floor free from spillage,
                    silverware, broken
                    glassware or
                    other hazards?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="152"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Is floor free from spillage,
                                                    silverware, broken
                                                    glassware or
                                                    other hazards?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="153"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is floor free from spillage,
                                                silverware, broken
                                                glassware or
                                                other hazards?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Is there a portable sign to
                    indicate wet floor or
                    temporary hazard?.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="154"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Is there a portable sign to
                                                    indicate wet floor or
                                                    temporary hazard?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="155"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is there a portable sign to
                                                indicate wet floor or
                                                temporary hazard?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is there a floor mat or any other
                    type of mat provided
                    at the lobby
                    entrance?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="156"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Is there a floor mat or any other
                                                    type of mat provided
                                                    at the lobby
                                                    entrance?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="157"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is there a floor mat or any other
                                                type of mat provided
                                                at the lobby
                                                entrance?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the kitchen, lobby and external
                    floor kept clean and
                    well maintained?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="158"
                      class=" house col-form-check form-check-inline ans"
                      data-name="Is the kitchen, lobby and external
                                                    floor kept clean and
                                                    well maintained?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="159"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is the kitchen, lobby and external
                                                floor kept clean and
                                                well maintained?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <div
                    id="160"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="161"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>

              <div>
                <h4 class="subtitle"><u>C. RECIPE COMPLIANCE</u></h4>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">14.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> FOOD PREPARATION </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all food stored or prepared in
                    the restaurant from
                    approved sources?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="162"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Are all food stored or prepared in
                                                    the restaurant from
                                                    approved sources?"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="163"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all food stored or prepared in
                                                the restaurant from
                                                approved sources?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are food equipment, utensils and
                    food contact surfaces
                    properly
                    washed, rinsed and sanitized before every use.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="164"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Are food equipment, utensils and
                                                    food contact surfaces
                                                    properly
                                                    washed, rinsed and sanitized before every use."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="165"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are food equipment, utensils and
                                                food contact surfaces
                                                properly
                                                washed, rinsed and sanitized before every use."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are frozen food thawed under
                    refrigeration, cooked to
                    proper
                    temperature from frozen state, or in cold running water. </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="166"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Are frozen food thawed under
                                                    refrigeration, cooked to
                                                    proper
                                                    temperature from frozen state, or in cold running water."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="167"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are frozen food thawed under
                                                refrigeration, cooked to
                                                proper
                                                temperature from frozen state, or in cold running water."
                  >
                    <p class="form-control">
                    </p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Thawed food is not refrozen.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="168"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Thawed food is not refrozen."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="169"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Thawed food is not refrozen."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Preparation is planned so food is
                    kept out of the
                    temperature
                    danger zone to the extent possible.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="170"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Preparation is planned so food is
                                                    kept out of the
                                                    temperature
                                                    danger zone to the extent possible."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="171"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Preparation is planned so food is
                                                kept out of the
                                                temperature
                                                danger zone to the extent possible."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Food is tasted using the proper
                    procedure..</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="172"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Food is tasted using the proper
                                                    procedure."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="173"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Food is tasted using the proper
                                                procedure."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Procedures are in place to prevent
                    cross-contamination.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="174"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Procedures are in place to prevent
                                                    cross-contamination."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="175"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Procedures are in place to prevent
                                                cross-contamination."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">h.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Food is handled with suitable
                    utensils, such as single
                    use gloves or
                    tongs.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="176"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Food is handled with suitable
                                                    utensils, such as single
                                                    use gloves or
                                                    tongs."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="177"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Food is handled with suitable
                                                utensils, such as single
                                                use gloves or
                                                tongs."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">i.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Food is prepared in small batches
                    to limit the time it
                    is in the
                    temperature danger zone.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="178"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Food is prepared in small batches
                                                    to limit the time it
                                                    is in the
                                                    temperature danger zone."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="179"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Food is prepared in small batches
                                                to limit the time it
                                                is in the
                                                temperature danger zone."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">j.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Clean reusable towels are used
                    only for cleaning and
                    sanitizing
                    equipment and surfaces and not for drying hands, utensils, or floor.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="180"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Clean reusable towels are used
                                                    only for cleaning and
                                                    sanitizing
                                                    equipment and surfaces and not for drying hands, utensils, or floor."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="181"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Clean reusable towels are used
                                                only for cleaning and
                                                sanitizing
                                                equipment and surfaces and not for drying hands, utensils, or floor."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">k.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Food is cooked to the required
                    safe internal
                    temperature for the
                    appropriate time. The temperature is tested with a calibrated food
                    probe thermometer.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="182"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="Food is cooked to the required
                                                    safe internal
                                                    temperature for the
                                                    appropriate time. The temperature is tested with a calibrated food
                                                    probe thermometer."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="183"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Food is cooked to the required
                                                safe internal
                                                temperature for the
                                                appropriate time. The temperature is tested with a calibrated food
                                                probe thermometer."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">l.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">The internal temperature of food
                    being cooked is
                    monitored and
                    documented.
                  </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="184"
                      class="recipe col-form-check form-check-inline ans"
                      data-name="The internal temperature of food
                                                    being cooked is
                                                    monitored and
                                                    documented."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="185"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="The internal temperature of food
                                                being cooked is
                                                monitored and
                                                documented."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <div
                    id="186"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="187"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>

              <!-- food taste -->

              <div>
                <h4 class="subtitle"><u>D. FOOD TASTE /SENSORY EVALUATION</u></h4>
                <div class="d-flex ">
                  <table class="table table-bordered ">
                    <thead>
                      <th>Name of Food</th>
                      <th
                        colspan="4"
                        id="188"
                        class="ans"
                        data-name="Name of food1"
                      >
                        <p></p>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td colspan="4">Score</td>
                      </tr>
                      <tr
                        class="food"
                        data-name="appearance1"
                      >

                        <td>Appearance</td>
                        <td
                          colspan="4"
                          id="189"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="taste1">
                        <td>Taste</td>
                        <td
                          colspan="4"
                          id="190"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="Aroma1">
                        <td>Aroma</td>
                        <td
                          colspan="4"
                          id="191"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="salt1">
                        <td>Salt</td>
                        <td
                          colspan="4"
                          id="192"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="texture1">
                        <td>Texture</td>
                        <td
                          colspan="4"
                          id="193"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="after taste1">
                        <td>After Taste</td>
                        <td
                          colspan="4"
                          id="194"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="spiciness1">
                        <td>Spiciness</td>
                        <td
                          colspan="4"
                          id="195"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered  ml-3 ">
                    <thead>
                      <th>Name of Food</th>
                      <th
                        colspan="4"
                        id="196"
                        class="ans"
                        data-name="Name of food2"
                      >
                        <p></p>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td colspan="4">Score</td>
                      </tr>
                      <tr data-name="appearance2">
                        <td>Appearance</td>
                        <td
                          colspan="4"
                          id="197"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="taste2">
                        <td>Taste</td>
                        <td
                          colspan="4"
                          id="198"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="Aroma2">
                        <td>Aroma</td>
                        <td
                          colspan="4"
                          id="199"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="salt2">
                        <td>Salt</td>
                        <td
                          colspan="4"
                          id="200"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="texture2">
                        <td>Texture</td>
                        <td
                          colspan="4"
                          id="201"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="after taste2">
                        <td>After Taste</td>
                        <td
                          colspan="4"
                          id="202"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="spiciness2">
                        <td>Spiciness</td>
                        <td
                          colspan="4"
                          id="203"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="d-flex">

                  <table class="table table-bordered ">
                    <thead>
                      <th>Name of Food</th>
                      <th
                        colspan="4"
                        id="204"
                        class="ans"
                        data-name="Name of food3"
                      >
                        <p></p>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td colspan="4">Score</td>
                      </tr>
                      <tr data-name="appearance3">
                        <td>Appearance</td>
                        <td
                          colspan="4"
                          id="205"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="taste3">
                        <td>Taste</td>
                        <td
                          colspan="4"
                          id="206"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="Aroma3">
                        <td>Aroma</td>
                        <td
                          colspan="4"
                          id="207"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="salt3">
                        <td>Salt</td>
                        <td
                          colspan="4"
                          id="208"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="texture3">
                        <td>Texture</td>
                        <td
                          colspan="4"
                          id="209"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="after taste3">
                        <td>After Taste</td>
                        <td
                          colspan="4"
                          id="210"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="spiciness3">
                        <td>Spiciness</td>
                        <td
                          colspan="4"
                          id="211"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered ml-3">
                    <thead>
                      <th>Name of Food</th>
                      <th
                        colspan="4"
                        id="212"
                        class="ans"
                        data-name="Name of food4"
                      >
                        <p></p>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td colspan="4">Score</td>
                      </tr>
                      <tr data-name="appearance4">
                        <td>Appearance</td>
                        <td
                          colspan="4"
                          id="213"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="taste4">
                        <td>Taste</td>
                        <td
                          colspan="4"
                          id="214"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="aroma4">
                        <td>Aroma</td>
                        <td
                          colspan="4"
                          id="215"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="salt4">
                        <td>Salt</td>
                        <td
                          colspan="4"
                          id="216"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="texture4">
                        <td>Texture</td>
                        <td
                          colspan="4"
                          id="217"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="after taste4">
                        <td>After Taste</td>
                        <td
                          colspan="4"
                          id="218"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                      <tr data-name="spiciness4">
                        <td>Spiciness</td>
                        <td
                          colspan="4"
                          id="219"
                          class="ans"
                        >
                          <p></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br>
                <div class="d-flex justify-content-around">
                  <div
                    id="220"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="221"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>

              <div>

                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">E.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> FOOD SERVICE </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are front of house supervisors
                    providing leadership to
                    their team </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="222"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Are front of house supervisors
                                                    providing leadership to
                                                    their team"
                    >
                      <p class="form-control"></p>

                    </div>

                  </div>
                  <div
                    id="223"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are front of house supervisors
                                                providing leadership to
                                                their team"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are all FOH staff wearing name
                    tags?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="224"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name=" Are all FOH staff wearing name
                                                    tags?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="225"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name=" Are all FOH staff wearing name
                                                tags?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Were customers served within 3
                    minutes or were they
                    courteously
                    informed of a realistic time frame to expect service </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="226"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Were customers served within 3
                                                    minutes or were they
                                                    courteously
                                                    informed of a realistic time frame to expect service"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="227"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Were customers served within 3
                                                minutes or were they
                                                courteously
                                                informed of a realistic time frame to expect service"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Did cashier suggest compliments to
                    the customer’s
                    original order
                    in order to upsell and improve on the customer’s experience?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="228"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Did cashier suggest compliments to
                                                    the customer’s
                                                    original order
                                                    in order to upsell and improve on the customer’s experience?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="229"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Did cashier suggest compliments to
                                                the customer’s
                                                original order
                                                in order to upsell and improve on the customer’s experience?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">e.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are all customers thanked after
                    service and encouraged
                    to
                    visit again?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="230"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Are all customers thanked after
                                                    service and encouraged
                                                    to
                                                    visit again?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="231"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are all customers thanked after
                                                service and encouraged
                                                to
                                                visit again?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">f.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Is the lobby cool and are A/Cs
                    functioning at the
                    desired
                    temperature is 18 deg C.</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="232"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Is the lobby cool and are A/Cs
                                                    functioning at the
                                                    desired
                                                    temperature is 18 deg C."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="233"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Is the lobby cool and are A/Cs
                                                functioning at the
                                                desired
                                                temperature is 18 deg C."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">g.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Do customers generally appear
                    happy or satisfied about
                    the
                    speed, quality of food and/or service and//or their experience
                    at this restaurant?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="234"
                      class="foodservice col-form-check form-check-inline ans"
                      data-name="Do customers generally appear
                                                    happy or satisfied about
                                                    the
                                                    speed, quality of food and/or service and//or their experience
                                                    at this restaurant?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="235"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Do customers generally appear
                                                happy or satisfied about
                                                the
                                                speed, quality of food and/or service and//or their experience
                                                at this restaurant?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <div
                    id="236"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="237"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <hr>

              <div>

                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1 text-bold">F.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5 text-bold"> HOSTING </p>
                  <p class="col-md-2 col-sm-2 col-xs-2 text-bold">1-5</p>
                  <p class="col-md-3 col-sm-3 col-xs-3 text-bold">Remarks</p>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">a.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are the lobby hosts in the lobby
                    wearing a smile and
                    eager to serve? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="238"
                      class="hosting col-form-check form-check-inline ans"
                      data-name="Are the lobby hosts in the lobby
                                                    wearing a smile and
                                                    eager to serve? "
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="239"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are the lobby hosts in the lobby
                                                wearing a smile and
                                                eager to serve? "
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">b.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5"> Are customers asked if they
                    enjoyed their meals and
                    are all negative
                    verbal feedbacks passed on to the shift managers. </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="240"
                      class="hosting col-form-check form-check-inline ans"
                      data-name="Are customers asked if they
                                                    enjoyed their meals and
                                                    are all negative
                                                    verbal feedbacks passed on to the shift managers."
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="241"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are customers asked if they
                                                enjoyed their meals and
                                                are all negative
                                                verbal feedbacks passed on to the shift managers."
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">c.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are customers handed response
                    cards and encouraged to
                    fill them? </p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="242"
                      class="hosting col-form-check form-check-inline ans"
                      data-name="Are customers handed response
                                                    cards and encouraged to
                                                    fill them?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="243"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are customers handed response
                                                cards and encouraged to
                                                fill them?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>
                <div class="row container">
                  <p class="col-md-1 col-sm-1 col-xs-1">d.</p>
                  <p class="col-md-5 col-sm-5 col-xs-5">Are hosts cleaning up the tables
                    and chairs after
                    customers have
                    left in a timely manner?</p>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <div
                      id="244"
                      class="hosting col-form-check form-check-inline ans"
                      data-name="Are hosts cleaning up the tables
                                                    and chairs after
                                                    customers have
                                                    left in a timely manner?"
                    >
                      <p class="form-control"></p>
                    </div>

                  </div>
                  <div
                    id="245"
                    class="col-md-3 col-sm-3 col-xs-3 ans"
                    data-name="Are hosts cleaning up the tables
                                                and chairs after
                                                customers have
                                                left in a timely manner?"
                  >
                    <p class="form-control"></p>
                  </div>
                </div>

                <div class="d-flex justify-content-around">
                  <div
                    id="246"
                    class="score subtitle ans question-hygiene"
                    data-name="score earned"
                  ><span>Score Earned</span>
                    <p class="form-control"></p>
                  </div> &nbsp;&nbsp;&nbsp;
                  <div
                    id="247"
                    class="score subtitle ans question-hygiene"
                    data-name="percent earned"
                  ><span>Percent Earned</span>
                    <p class="form-control"></p>
                  </div>
                </div>

              </div>
              <br>
              <div
                id="248"
                class="form-group row ans"
                data-name="Auditor Name"
              >
                <label
                  for="staticEmail"
                  class="col-xs-4 col-sm-4 col-form-label"
                >General Comment</label>
                <div class="col-xs-8 col-sm-8 ans">
                  <p class="form-box"></p>
                </div>
              </div>

              <div class="d-flex justify-content-around">
                <div
                  id="249"
                  class="score subtitle ans question-hygiene"
                  data-name="score earned"
                ><span>Total Score Earned</span>
                  <p class="form-control"></p>
                </div> &nbsp;&nbsp;&nbsp;
                <div
                  id="250"
                  class="score subtitle ans question-hygiene"
                  data-name="percent earned"
                ><span>Total Percent Earned</span>
                  <p class="form-control"></p>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () {
    this.getForm(this.$route.params.id);
  },
  methods: {
    getForm (id) {
      var recent = {
        what: "foodsafetyview",
        // showLoader: "no",
        params: {
          form_id: id
        }
      };

      this.$socket
        .makeGetRequest(recent)
        .then(response => {
          if (response.type == "foodsafetyview") {
            for (let i = 0; i <= 400; i++) {
              response.data.forEach(item => {
                if (i == item.questionno) {
                  // console.log(item);
                  // console.log(item.questiontext)
                  if (item.questiontext == "Shop Name Shop") {
                    let stores = this.$store.getters.stores
                    stores.forEach(j => {
                      if (j.id == item.answers) {
                        item.answers = j.address + ", " + j.location;
                      }
                    })
                  }
                  document
                    .getElementById(i)
                    .querySelectorAll("p")[0].innerHTML = item.answers;
                }
              });
            }


          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
<style scoped>
.form-box {
  border: 1px solid #dee2e6;
  height: 100px;
  width: 100%;
  padding: 5px;
}
</style>
